import {type Writable, writable} from 'svelte/store';
import {
	routesDefinition,
	type RouteDefinition
} from '$lib/utils/navigation/routesDefinition';

interface RouteDefinitionStore extends Writable<RouteDefinition[]> {
	routesDefinition: (routesDefinition: RouteDefinition[]) => void;
}

function createRoutesDefinitionStore (): RouteDefinitionStore {
	const {subscribe, set, update} =
		writable<RouteDefinition[]>(routesDefinition);

	return {
		subscribe,
		set,
		update,
		routesDefinition: (routesDefinition) =>
			routesDefinitionStore.set(routesDefinition)
	};
}

export const routesDefinitionStore = createRoutesDefinitionStore();

export const customerTypesStore = writable<string[]>([]);
export const activityAreaStore = writable<string[]>([]);
